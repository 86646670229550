:root {
  --main-color: rgb(255, 255, 255); /* Main color (white) */
  --accent-color-1: rgb(10, 10, 10); /* black */
  --accent-color-2: rgb(233, 63, 51); /* red */
}

body {
  background-image: url('../public/misc_pics/main_pic.jpeg'); /* Update this path to your background image */
  background-size: cover;
  background-position: center;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: var(--main-color); /* Apply main color to background */
  color: var(--accent-color-1); /* Apply first accent color to text */
}

p {
  font-weight:700;
}

.app-container {
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for better readability */
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px;
  margin: auto;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px 0;
  background-color: var(--accent-color-2); /* Apply second accent color to header background */
  color: var(--accent-color-1); /* Apply main color to header text */
}

.title {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
}

.beta {
  font-size: 1.5em;
  margin-top: 0;
}

.entry-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.2em;
  display: inline-block;
  transition: transform 0.3s ease;
}

.entry-button:hover {
  transform: scale(1.05); /* Increase size slightly on hover */
}

.entry-form {
  text-align: center;
  margin-bottom: 20px;
}
.state-blocks-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px; /* Add spacing between header and state blocks */
}

.state-block {
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  flex: 1 0 25%; /* Adjust the width of the state blocks */
  margin: 10px;
  padding: 20px;
  background-color: var(--main-color); /* Apply main color to state block background */
  border-radius: 5px;
  border-color: var(--accent-color-2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Light shadow */
  text-align: center;
  transition: transform 0.3s ease;
  text-decoration: none; /* Remove underline */
  color: var(--accent-color-1); /* Apply first accent color to state name text */
}

.state-block:hover {
  transform: scale(1.05); /* Increase size slightly on hover */
}

.state-block h2 {
  margin-bottom: 10px;
}

.state-block a {
  text-decoration: none; /* Remove underline */
  color: var(--accent-color-1); /* Apply first accent color to state name text */
}

.hubs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px; /* Add a max-width to the hubs container */
  margin: auto; /* Center the hubs container */
}

.hub-block {
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  flex: 1 0 45%; /* Adjust width to fit within the container */
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.hub-block h3 {
  margin-bottom: 10px;
}

.state-image {
  width: 200px; /* Set a fixed width */
  height: 150px; /* Set a fixed height */
  object-fit: contain; /* Scale the image to fit within the container */
  margin: 10px; /* Optional: Add some margin for spacing */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better appearance */
  background-color: #f0f0f0; /* Optional: Add a background color to fill the empty space */
}

.state-page {
  text-align: center; /* Center align state name */
  padding: 20px;
}

.state-title {
  background: var(--main-color);
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 2.5em;
}

.city-header {
  font-size: 25px;
}

.back-link-container {
  position: fixed;
  top: 20px; /* Adjust top position as needed */
  left: 20px; /* Adjust left position as needed */
  z-index: 999; /* Ensure link is above other content */
}

.back-link {
  text-decoration: none;
  color: #333;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
}